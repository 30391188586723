import "./App.css";

function App() {
  return (
    <div id="page-top">
      {/* <!-- Navigation --> */}
      <nav
        className="navbar fixed-top navbar-expand-md navbar-inverse"
        id="mainNav"
      >
        <div className="container">
          <button
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu <i className="fa fa-bars"></i>
          </button>
          <img src="img/logo.png" id="logo" alt="" />
          <a className="navbar-brand page-scroll" href="#page-top">
            &nbsp;&nbsp;&nbsp;&nbsp;your IT company!
          </a>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#services">
                  Services&nbsp;
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#portfolio">
                  Portfolio&nbsp;
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#about">
                  About&nbsp;
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link page-scroll" href="#team">
                  Team&nbsp;
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link page-scroll navbar-toggler-right"
                  href="#contact"
                >
                  Contact&nbsp;
                </a>
              </li>
            </ul>
          </div>
          <button className="btn btn-xs" type="button" id="btnLogIn">
            Log in<i className="fa fa-bars"></i>
          </button>
        </div>
      </nav>

      {/* <!-- Header --> */}
      <header>
        <div className="container">
          <div className="intro-text">
            <div className="intro-lead-in">
              Welcome to your IT excellence company
            </div>
            <div className="intro-heading">Join us and love IT</div>
            <a href="#services" className="page-scroll btn btn-xl">
              Learn more
            </a>
          </div>
        </div>
      </header>

      {/* <!-- Services --> */}
      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Services</h2>
              <h3 className="section-subheading text-muted">
                IT Architecture in all levels
              </h3>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Consultancy</h4>
              <p className="text-muted">
                We are very experienced in several industry segments, e.g.
                banking, retail, ERP, insurance, telecom...
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-laptop fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Design on demand</h4>
              <p className="text-muted">
                We assess your business cases building a technical architecture
                designed and tailored based on your requirements supporting all
                life project cycles, even your governance process.
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fa fa-circle fa-stack-2x text-primary"></i>
                <i className="fa fa-lock fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="service-heading">Bespoke solutions</h4>
              <p className="text-muted">
                Our solutions are created following the design principles, clean
                and simple architecture that suits your use cases targeting a
                very most desirable growing business.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Portfolio Grid --> */}
      <section id="portfolio" className="bg-faded">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Portfolio</h2>
              <h3 className="section-subheading text-muted">
                Areas of expertise
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/reactive-manifesto.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>Responsive Applications</h4>
                <p className="text-muted">Reactive Manifesto</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/ms-architecture.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>Micro-services Architecture</h4>
                <p className="text-muted">Re-design your landscape</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/api-revolution.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>API Revolution</h4>
                <p className="text-muted">Service Maturity</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/event-sourcing.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>Event Sourcing</h4>
                <p className="text-muted">Do more with your data</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/clean-architecture.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>Clean Architecture</h4>
                <p className="text-muted">No more overengineering</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 portfolio-item">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content">
                  <i className="fa fa-plus fa-3x"></i>
                </div>
              </div>
              <img
                src="img/portfolio/bring-it-on.png"
                className="img-fluid"
                alt=""
              />

              <div className="portfolio-caption">
                <h4>Tibco, Containers, Cloud, AWS...</h4>
                <p className="text-muted">Bring it on...</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- About --> */}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">About</h2>
              <h3 className="section-subheading text-muted">
                Some information about our journey so far
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="timeline">
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>1990-1996</h4>
                      <h4 className="subheading">First steps</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        From Pascal and Cobol running a CP500 machine to Delphi
                        with Paradox and Oracle databases.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>1997-1999</h4>
                      <h4 className="subheading">Professional Realization</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        The pleasure to make customers and business happy as
                        ever before, implementing a bespoke CRM platform.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/3.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>2000-2005</h4>
                      <h4 className="subheading">
                        Distributed Applications and Web revolution
                      </h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        Corba, Dcom, RPC and Java. Build bespoke frameworks and
                        consolidation of SOLID principles and Clean
                        architecture.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/4.jpg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>2006-2017</h4>
                      <h4 className="subheading">Design</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        Thousands of applications bouncing inside an enterprise
                        landscape. Strategic views combined with roadmaps can
                        lead you to a successful business avoiding all pitfalls
                        of a growing business.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/5.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>2017</h4>
                      <h4 className="subheading">Cloud Evolution</h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        We are cloud specialists, if you are thinking on
                        migrating, adopt fully managed cloud services, go
                        serverless and, create and build your cloud
                        infrastructure talk to us. Embrace the time-to-market,
                        velocity, elasticity, resilience and provision of
                        resourses that was never possible before.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="timeline-image">
                    <img
                      className="rounded-circle img-fluid"
                      src="img/about/web-3.0.png"
                      alt=""
                    />
                  </div>
                  <div className="timeline-panel">
                    <div className="timeline-heading">
                      <h4>2021</h4>
                      <h4 className="subheading">
                        AI and Web3 - Smart Contracts, NFTs, Wallet...
                      </h4>
                    </div>
                    <div className="timeline-body">
                      <p className="text-muted">
                        We help customers to take advantage of AI on their
                        existing business offers and new opportunities. Web3
                        represents the evolution of how technology and data are
                        used, leading to a wave of disruptive innovation across
                        the globe, our aim is help customers in this new road.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="timeline-inverted">
                  <div className="timeline-image">
                    <h4>
                      Be Part
                      <br />
                      Of Our
                      <br />
                      Story!
                    </h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Team --> */}
      <section id="team" className="bg-faded">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Our Amazing Team</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team-member">
                <img
                  src="img/team/1.jpg"
                  className="mx-auto rounded-circle"
                  alt=""
                />
                <h4>Leonardo Silva</h4>
                <p className="text-muted">Director</p>
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-twitter"></i></a> */}
                  </li>
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-facebook"></i></a> */}
                  </li>
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-linkedin"></i></a> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team-member">
                <img
                  src="img/team/2.jpg"
                  className="mx-auto rounded-circle"
                  alt=""
                />
                <h4>Fabiana Bertini</h4>
                <p className="text-muted">Director</p>
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-twitter"></i></a> */}
                  </li>
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-facebook"></i></a> */}
                  </li>
                  <li className="list-inline-item">
                    {/* <a href="#"><i className="fa fa-linkedin"></i></a> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team-member">
                <img
                  src="img/team/3.jpg"
                  className="mx-auto rounded-circle"
                  alt=""
                />
                <h4>You</h4>
                <p className="text-muted">Consultant</p>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#portfolioModal1"
                >
                  Opportunities
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <p className="large text-muted">
                “Everyone is a prisoner of his own experiences.” Edward R.
                Murrow.
              </p>
              <p className="large text-muted">
                Let's be free and have more experiences!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Clients --> */}
      {/* <aside className="clients">
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-sm-6">
                    <a href="#">
                        <img src="img/logos/envato.jpg" className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-3 col-sm-6">
                    <a href="#">
                        <img src="img/logos/designmodo.jpg" className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-3 col-sm-6">
                    <a href="#">
                        <img src="img/logos/themeforest.jpg" className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
                <div className="col-md-3 col-sm-6">
                    <a href="#">
                        <img src="img/logos/creative-market.jpg" className="img-fluid d-block mx-auto" alt=""/>
                    </a>
                </div>
            </div>
        </div>
    </aside>  */}

      {/* <!-- Contact --> */}
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Contact Us</h2>
              <h3 className="section-subheading text-muted">
                Let we know more about you. Send a message to us!
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form name="sentMessage" id="contactForm" noValidate>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name *"
                        id="name"
                        required
                        data-validation-required-message="Please enter your name."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email *"
                        id="email"
                        required
                        data-validation-required-message="Please enter your email address."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Your Phone *"
                        id="phone"
                        required
                        data-validation-required-message="Please enter your phone number."
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="Your Message *"
                        id="message"
                        required
                        data-validation-required-message="Please enter a message."
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-lg-12 text-center">
                    <div id="success"></div>
                    <button type="submit" className="btn btn-xl">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer --> */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <span className="copyright">
                Copyright &copy; Inetpub Ltd 2023
              </span>
            </div>
            <div className="col-md-4">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  {/* <a href="#"><i className="fa fa-twitter"></i></a> */}
                </li>
                <li className="list-inline-item">
                  {/* <a href="#"><i className="fa fa-facebook"></i></a> */}
                </li>
                <li className="list-inline-item">
                  {/* <a href="#"><i className="fa fa-linkedin"></i></a> */}
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-inline quicklinks">
                <li className="list-inline-item">Privacy Policy</li>
                <li className="list-inline-item">Terms of Use</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      {/* <!-- Portfolio Modals --> */}

      {/* <!-- Modal 1 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal1"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Solution Architects</h2>
                    <p className="item-intro text-muted">Consultants</p>
                    <p>
                      If you are looking for a relaxed environment where the aim
                      is to have quality in life and make our Customers business
                      a success, here is the place for you. Please contact us
                      using the Contact Us session.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      Back <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 2 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal2"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src="img/portfolio/02-full.jpg"
                      alt=""
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>Date: January 2017</li>
                      <li>Client: Explore</li>
                      <li>Category: Graphic Design</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 3 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal3"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src="img/portfolio/03-full.jpg"
                      alt=""
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>Date: January 2017</li>
                      <li>Client: Finish</li>
                      <li>Category: Identity</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 4 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal4"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src="img/portfolio/04-full.jpg"
                      alt=""
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>Date: January 2017</li>
                      <li>Client: Lines</li>
                      <li>Category: Branding</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 5 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal5"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src="img/portfolio/05-full.jpg"
                      alt=""
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>Date: January 2017</li>
                      <li>Client: Southwest</li>
                      <li>Category: Website Design</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal 6 --> */}
      <div
        className="portfolio-modal modal fade"
        id="portfolioModal6"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="modal-body">
                    {/* <!-- Project Details Go Here --> */}
                    <h2>Project Name</h2>
                    <p className="item-intro text-muted">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                    <img
                      className="img-fluid d-block mx-auto"
                      src="img/portfolio/06-full.jpg"
                      alt=""
                    />
                    <p>
                      Use this area to describe your project. Lorem ipsum dolor
                      sit amet, consectetur adipisicing elit. Est blanditiis
                      dolorem culpa incidunt minus dignissimos deserunt repellat
                      aperiam quasi sunt officia expedita beatae cupiditate,
                      maiores repudiandae, nostrum, reiciendis facere nemo!
                    </p>
                    <ul className="list-inline">
                      <li>Date: January 2017</li>
                      <li>Client: Window</li>
                      <li>Category: Photography</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i> Close Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
